import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';

interface Configuration {
  production: boolean;
  apiEndPoint: string;
  version: string;
  apiForgotPassword: string;
}

@Injectable({ providedIn: 'root' })
export class ConfigAssetLoaderService {

  private CONFIG_URL = 'assets/config/config.json';
  public configData: Configuration;

  constructor(private http: HttpClient) {
  }

  private setConfigUrl(): void {
    const hostname = window && window.location && window.location.hostname;
    if (/^.*localhost.*/.test(hostname)) {
      this.CONFIG_URL = "assets/config/config.json";
    } else if (/^.*beta.bcrm.philkotse.com*/.test(hostname)) {
      this.CONFIG_URL = "assets/config/configStaging.json";
    } else if (/^.*bcrm.philkotse.com*/.test(hostname)) {
      this.CONFIG_URL = "assets/config/configProduction.json";
    } else if (/^.*dvg-lc.*/.test(hostname) || /^.*172.16.0.*/.test(hostname)) {
      this.CONFIG_URL = "assets/config/configTesting.json";
    }
  }

  public initConfigurations(): any {
    if (!this.configData) {
      this.setConfigUrl();
      return this.http
        .get<Configuration>(this.CONFIG_URL)
        .subscribe(result => {
          this.configData = {
            apiEndPoint: result["apiEndPoint"],
            production: result["production"],
            version: result["version"],
            apiForgotPassword: result["apiForgotPassword"]
          }
        });
    }
  }

  get config(): Configuration {
    return this.configData;
  }
}
