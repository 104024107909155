import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './modules/main/main.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedcomponentModule } from './modules/sharedcomponent/sharedcomponent.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ScriptLoaderService } from './services/script-loader.service';
import { DataSharingService } from './services/data-sharing.service';
import { ConfigAssetLoaderService } from './services/config-asset-loader.service';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedcomponentModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  //useFactory: (envConfigService: ConfigAssetLoaderService) => () => envConfigService.initConfigurations().toPromise(),
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: (envConfigService: ConfigAssetLoaderService) => () => envConfigService.initConfigurations(),
    deps: [ConfigAssetLoaderService],
    multi: true
  },
    ScriptLoaderService, CookieService, DataSharingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
