import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.css']
})
export class PagingComponent implements OnInit {
  @Output() searchEvent = new EventEmitter<number>();
  @Input() pageIndex: number;
  @Input() pageSize: number;
  @Input() pageShow: number;
  @Input() totalRecord: number;
  constructor(private dialog: DialogService) { }
  ngOnInit() {
    if (this.pageShow === undefined || this.pageShow === null || this.pageShow < 0) {
      this.pageShow = 10;
    }
  }

  get totalPage() {
    if (this.totalRecord % this.pageSize !== 0) {
      return (Math.floor(this.totalRecord / this.pageSize)) + 1;
    } else {
      return this.totalRecord / this.pageSize;
    }
  }
  get previousPage() {
    return this.pageIndex === 1 ? 1 : this.pageIndex - 1;
  }
  get nextPage() {
    return this.pageIndex === this.totalPage ? this.totalPage : this.pageIndex + 1;
  }
  getShowIndexPage() {
    if (this.totalRecord === 0) {
      return [];
    }
    const size = this.dialog.SizeMonitor();
    const arrIndex = [];
    let start = 0;
    let end = 0;

    if (this.totalPage <= this.pageShow) {
      for (let i = 1; i <= this.totalPage; i++) {
        arrIndex.push(i);
      }
    } else {
      if (this.pageIndex < this.pageShow) {
        for (let i = 1; i <= this.pageShow; i++) {
          arrIndex.push(i);
        }
      } else {
        start = this.pageIndex - parseInt((this.pageShow / 2).toString(), 10);
        end = this.pageIndex + parseInt((this.pageShow / 2).toString(), 10);

        start = start < 1 ? 1 : start;
        end = end > this.totalPage ? this.totalPage : end;

        for (let i = start; i <= end; i++) {
          arrIndex.push(i);
        }
      }
    }

    return arrIndex;
  }

  callParentSearch(showPage: number) {
    this.pageIndex = showPage;
    this.searchEvent.emit(showPage);
  }
}
