import { Component, OnInit } from '@angular/core';
import { AuthenticatedUser } from 'src/app/models/authenticateduser';
import { Role } from 'src/app/enums/roles';
import { environment } from '../../../../../environments/environment'
@Component({
  selector: 'app-base',
  template: ''
})
export class BaseComponent implements OnInit {

  currUser: AuthenticatedUser;

  constructor() {
    this.currUser = JSON.parse(localStorage.getItem('currentUser')) as AuthenticatedUser;
  }

  ngOnInit() {
  }
  hasRole(checkedRole: Role): boolean {
    const user = JSON.parse(localStorage.getItem('currentUser')) as AuthenticatedUser;
    if (user !== undefined && user != null) {
      if (user.Roles.includes(checkedRole)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  checkIsManager() {
    const bool = this.hasRole(Role.Admin) || this.hasRole(Role.Manager);
    return bool;
  }
  checkIsAdmin() {
    const bool = this.hasRole(Role.Admin);
    return bool;
  }
  checkIsAccountant() {
    const bool = this.hasRole(Role.Accountant);
    return bool;
  }
  checkIsSale() {
    const user = JSON.parse(localStorage.getItem('currentUser')) as AuthenticatedUser;
    let bool = false;
    if (this.hasRole(Role.Saler) && user.Roles.length === 1) {
      bool = true;
    }
    return bool;
  }
  checkIsCustomerCare() {
    const user = JSON.parse(localStorage.getItem('currentUser')) as AuthenticatedUser;
    let bool = false;
    if (this.hasRole(Role.CustomerCare) && user.Roles.length === 1) {
      bool = true;
    }
    return bool;
  }
  //GiangHT2: Thêm case này cho 1 bạn sale cần xử lý đặc biệt
  IsPecialSaler() {
    const user = JSON.parse(localStorage.getItem('currentUser')) as AuthenticatedUser;
    let arrUserName = environment.pecUser.split(',');
    if (arrUserName.includes(user.UserName)) {
      return true;
    }
    return false;
  }

  checkIsNullOrEmpty(obj: any, noZero = false) {
    if (obj === undefined || obj === null || obj === '') {
      return true;
    }

    if (noZero && obj.toString() === '0') {
      return true;
    }

    return false;
  }

  formatNumber(obj: number, value = -1) {
    if (obj === undefined || obj === null || obj.toString() === '0' || obj.toString() === 'NaN') {
      return value != undefined && value >= 0 ? value.toString() : '';
    }

    return obj.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  formatNumberHadNumber0(obj: number, value = -1) {
    if (obj === undefined || obj === null || obj.toString() === 'NaN') {
      return value != undefined && value >= 0 ? value.toString() : '';
    }

    return obj.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  getNumberFromString(obj: string, defaultReturn = 0) {
    if (obj === undefined || obj === '') {
      return defaultReturn;
    }

    obj = obj.toString().replace(/[^0-9]+/gi, '');
    return parseInt(obj.toString(), 10);
  }



  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      // tslint:disable-next-line:no-bitwise
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  }

  downloadFile(data: any, fileName = 'export file.xlsx') {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
    const dataURL = window.URL.createObjectURL(blob);

    //window.open(dataURL, '_blank');

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //   window.navigator.msSaveOrOpenBlob(blob);
    //   return;
    // }

    const link = document.createElement('a');
    link.href = dataURL;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      document.body.removeChild(link);
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(dataURL);
    }, 100);
  }

  subStringNote(note: string) {
    if (note == null || note === '') {
      return '';
    }

    const maxlength = 20;
    if (note.length > maxlength) {
      return '<label title="' + note + '">' + note.substring(0, maxlength) + '...' + '</label>';
    } else {
      return '<label title="' + note + '">' + note + '</label>';
    }
  }
  encodeToJson(value: any) {
    return encodeURIComponent(JSON.stringify(value));
  }

  newlines(text: string) {
    return text.replace(/\n/g, '<br/>');
  }

  saveFile(name, data) {
    const blob = new Blob([this.s2ab(atob(data))], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
    });

    if (blob != null && navigator.msSaveBlob)
      return navigator.msSaveBlob(blob, name);
    var a = $("<a class='downloadBlob' style='display: none;'/>");
    var url = window.URL.createObjectURL(blob);
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    let element: HTMLElement = document.getElementsByClassName('downloadBlob')[0] as HTMLElement;
    element.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  roundToTwo(num) {
    return Math.round(num * 100) / 100;
  }

  generatePassword() {
    var buf = new Uint8Array(6);
    window.crypto.getRandomValues(buf);
    return btoa(String.fromCharCode.apply(null, buf));
  }

  getStartDateOfMonth() {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    return new Date(year, month, 1);
  }

}
