import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './modules/main/main.component';
import { Role } from './enums/roles';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: '', component: MainComponent,
    children: [
      { path: 'home', loadChildren: './modules/backend/backend.module#BackendModule'},
      { path: 'customer', loadChildren: './modules/customer/customer.module#CustomerModule', data: { showWithSpecSale: true } },
      { path: 'payment', loadChildren: './modules/payment/payment.module#PaymentModule' },
      { path: 'spending', loadChildren: './modules/spending/spending.module#SpendingModule' },
      { path: 'users', loadChildren: './modules/users/users.module#UsersModule' },
      { path: 'report', loadChildren: './modules/report/report.module#ReportModule', data: { roles: [Role.Admin, Role.Manager] } },
      { path: 'contact', loadChildren: './modules/contact/contact.module#ContactModule' },
      { path: 'customerlead', loadChildren: './modules/customerlead/customerlead.module#CustomerleadModule', data: { showWithSpecSale: true } },
      { path: 'customerrequest', loadChildren: './modules/customerrequest/customerrequest.module#CustomerRequestModule', data: { showWithSpecSale: true } },
      { path: 'kpi', loadChildren: './modules/kpi/kpi.module#KPIModule' },
      { path: 'opportunity', loadChildren: './modules/opportunity/opportunity.module#OpportunityModule' },
      { path: 'bannerads', loadChildren: './modules/bannerads/bannerads.module#BannerAdsModule' },
      { path: 'attendancereport', loadChildren: './modules/attendancereport/attendancereport.module#AttendanceReportModule' },
      { path: 'revenuereport', loadChildren: './modules/revenuereport/revenuereport.module#RevenueReportModule' },
      { path: 'transaction', loadChildren: './modules/transaction/transaction.module#TransactionModule' },
    ]
  },
  { path: 'login', loadChildren: './modules/login/login.module#LoginModule' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
